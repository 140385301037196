//******************************************************************************************
// Built and Tested with Stripo Version 20210730-1706_33bccf8  (Release 4.119 - August 2021)
// We are always using the latest release.
// https://stripo.email/releases/ - Monthly releases (rapid release, continuous bug fix)
//******************************************************************************************

var stripo = (function () {
  return {
    /** Authentication token obtained via Web API call (must be set before plugin is started).*/
    //token: string = '',
    tokenFunction: function(){},
    /** Plugin view only indicator (default - false, set before plugin is started). */
    viewOnly: boolean = false, // added for Pulse
    /** Pulse logged on user's full name (default - empty string, set before plugin is started). */
    UserFullName: string = '', // added for Pulse
    /** Pulse tenant id. */
    TenantId: string = '',
    /** Pulse email template id. */
    TemplateId: string = '',

    externalImagesLibrary: any = null,

    imageUrl: string = '',

    //// Utility methods
    //request: function (method, url, data, callback) {
    //  let req = new XMLHttpRequest();
    //  req.onreadystatechange = function () {
    //    if (req.readyState === 4 && req.status === 200) {
    //      callback(req.responseText);
    //    } else if (req.readyState === 4 && req.status !== 200) {
    //      console.error('Cannot complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values');
    //    }
    //  };
    //  req.open(method, url, true);
    //  if (method !== 'GET') {
    //    req.setRequestHeader('content-type', 'application/json');
    //  }
    //  req.send(data);
    //},

    /**
     * Initializes the Stripo plugin with specified template and then starts plugin.
     * (added Pulse specific TenantId and TemplateId arguments.)
     *
     * @param {any} callback Initialization callback (typically the initPlugin function).
     * @param {any} templateHtml HTML
     * @param {any} templateCss Style sheet
     * @param {any} tenantId
     * @param {any} templateId
     */
    loadTemplate: function (callback, templateHtml, templateCss, tenantId, templateId) {


      callback({ html: templateHtml, css: templateCss, tenantId: tenantId, templateId: templateId });
    },

    /**
     * Adds a border radius of 50% and sets sizes for images in the template
     * where the umage src URL matched the passed URL.
     *
     * (called from 'click' callback for a image selected from the Image Library pop-up)
     *
     * @param {any} url
     */
    makeAvatar: function (url) {
      if ((url == undefined) || (url == null))
        return;

      let previewFrame = document.getElementsByClassName('stripo-preview-frame');
      let doc = previewFrame[0].contentDocument;
      let images = doc.images;
      for (let x = 0; x < images.length; x++) {
        if (images[x].src == url) {
          let img = images[x];
          // initial sizes when placed into template
          img.style.borderRadius = "50%";
          img.style.maxWidth = "14rem";
          img.style.maxHeight = "14rem";
          img.style.minWidth = "14rem";
          img.style.minHeight = "14rem";
          img.style.objectFit = "cover";

          if (externalImagesLibrary !== null) {
            let imagePreview = document.getElementById('imgUploadSettingsControl-preview-image');
            if ((imagePreview !== undefined) && (imagePreview != null)) {
              imagePreviewStyle = imagePreview.style;
              imagePreviewStyle.borderRadius = "50%";
              imagePreviewStyle.maxWidth = "5.5rem";
              imagePreviewStyle.maxHeight = "5.5rem";
              imagePreviewStyle.minWidth = "5.5rem";
              imagePreviewStyle.minHeight = "5.5rem";
              imagePreviewStyle.objectFit = "cover";
            }
          }
        }
      }
    },

    /**
     * Removes border radius and sets sizes for images in the template
     * where the umage src URL matched the passed URL.
     *
     * (called from 'click' callback for a image selected from the Image Library pop-up)
     *
     * @param {any} url
     */
    clearAvatar: function (url) {
      if ((url == undefined) || (url == null))
        return;

      let previewFrame = document.getElementsByClassName('stripo-preview-frame');
      let doc = previewFrame[0].contentDocument;
      let images = doc.images;
      for (let x = 0; x < images.length; x++) {
        if (images[x].src == url) {
          let img = images[x];
          img.style.borderRadius = "unset";
          img.style.maxWidth = "unset";
          img.style.maxHeight = "unset";
          img.style.minWidth = "unset";
          img.style.minHeight = "unset";
          img.style.objectFit = "contain";
        }

        if (externalImagesLibrary !== null) {
          let imagePreview = document.getElementById('imgUploadSettingsControl-preview-image');
          if ((imagePreview !== undefined) && (imagePreview != null)) {
            imagePreviewStyle = imagePreview.style;
            imagePreviewStyle.borderRadius = "unset";
            imagePreviewStyle.maxWidth = "5.5rem";
            imagePreviewStyle.maxHeight = "5.5rem";
            imagePreviewStyle.minWidth = "5.5rem";
            imagePreviewStyle.minHeight = "5.5rem";
            imagePreviewStyle.objectFit = "contain";
          }
        }
      }
    },

    addPreviewImageClickEventHandlers() {
      if ((stripo.externalImagesLibrary === undefined) || (stripo.externalImagesLibrary === null))
        return;

      let previewFrame = document.getElementsByClassName('stripo-preview-frame');
      if ((previewFrame != undefined) && (previewFrame != null)) {
        let previewFrame = document.getElementsByClassName('stripo-preview-frame');
        let doc = previewFrame[0].contentDocument;
        let images = doc.images;

        if ((images != undefined) && (images != null)) {
          for (let x = 0; x < images.length; x++) {
            images[x].addEventListener('click', function (e) {
              stripo.externalImagesLibrary.setPreviewImageBorderRadius(e);
            });
          }
        }

        let blockImages = doc.getElementsByClassName('esd-block-image');
        if ((blockImages != undefined) && (blockImages != null)) {
          for (let x = 0; x < blockImages.length; x++) {
            blockImages[x].addEventListener('click', function (e) {
              stripo.externalImagesLibrary.setPreviewImageBorderRadius(e);
            });
          }
        }
      }
    },

    /**
     * Retrieves the HTML, CSS code, width and height for the compiled email.
     *
     * @param {any} callback Format: callback(HTML, CSS, width, height).
     * @param {any} minimize
     */
    getEmail: function (callback, minimize) {
      window.StripoApi.getEmail(callback, minimize);
    },

    /**
     * Retrieves compiled and compressed HTML code that is ready to be sent out to clients.
     *
     * @param {any} callback Format: callback(error, html, ampHtml, ampErrors).
     * @param {any} minimize
     */
    getCompiledEmail: function (callback, minimize) {
      window.StripoApi.compileEmail(callback, minimize);
    },

    getAllDataSaved: function () {
      return window.StripoApi.allDataSaved();
    },

    /**
     * Retrieves the HTML, CSS internal extra styles, width and height for the email template.
     *
     * @param {any} callback Format: callback(HTML, CSS, width, height).
     */
    getTemplate: function (callback) {
      window.StripoApi.getTemplate(callback);
    },

    /**
     * Returns the Stripo plugin version.
     */
    getVersion: function () {
      return window.StripoApi.getVersion();
    },

    /**
     * Returns the email title/subject line.
     */
    getTitle: function () {
      return window.StripoApi.getTitle();
    },

    /**
     * Sets the email title/subject line.
     *
     * @param {any} title
     */
    setTitle: function (title) {
      window.StripoApi.setTitle(title);
    },

    /**
     * Returns the email’s hidden preheader.
     */
    getHiddenPreHeader() {
      return window.StripoApi.getHiddenPreHeader();
    },

    /**
     * Sets an email’s hidden preheader.
     *
     * @param {any} hiddenPreHeader
     */
    setHiddenPreHeader(hiddenPreHeader) {
      window.StripoApi.setHiddenPreHeader(hiddenPreHeader);
    },

    /**
     * Inserts/updates the Gmail Promo Annotations’ microdata in HTML code of the opened email template in the editor.
     *
     * @param {any} googleAnnotations null or object specifying:
     *   logo: 'https://logo',
     *   description: 'description',
     *   discountCode: 'discountCode',
     *   availabilityStarts: '2019-06-26T04:04:00+00:00',
     *   availabilityEnds: '2019-06-30T04:04:00+00:00',
     *   image: 'https://image'
     */
    setGoogleAnnotations(googleAnnotations) {
      window.StripoApi.setGoogleAnnotations(googleAnnotations);
    },

    /**
     * Returns the Gmail Promo Annotations’ microdata inserted into the HTML code of the email template in the editor.
     *
     * @returns null or object specifying:
     *   logo: 'https://logo',
     *   description: 'description',
     *   discountCode: 'discountCode',
     *   availabilityStarts: '2019-06-26T04:04:00+00:00',
     *   availabilityEnds: '2019-06-30T04:04:00+00:00',
     *   image: 'https://image'
     */
    getGoogleAnnotations() {
      return window.StripoApi.getGoogleAnnotations();
    },

    /**
     * Initializes and starts the Stripo plugin.
     * (added Pulse specific TenantId and TemplateId to passed template argument.)
     *
     * @param {any} template
     */
    initPlugin: function (template) {
      const apiRequestData = {
        emailId: 123
        //emailId: template.tenantId + template.templateId
      };
      const script = document.createElement('script');
      script.id = 'stripoScript';
      script.type = 'text/javascript';
      script.src = 'https://plugins.stripo.email/static/rev/1.116.0/stripo.js';
      script.onload = function () {
        window.Stripo.init({
          settingsId: 'stripoSettingsContainer',
          previewId: 'stripoPreviewContainer',
          codeEditorButtonId: 'codeEditor',
          undoButtonId: 'undoButton',
          redoButtonId: 'redoButton',
          locale: 'en',
          html: template.html,
          css: template.css,
          notifications: {
            info: notifications.info.bind(notifications),
            error: notifications.error.bind(notifications),
            warn: notifications.warn.bind(notifications),
            loader: notifications.loader.bind(notifications),
            hide: notifications.hide.bind(notifications),
            success: notifications.success.bind(notifications)
          },
          apiRequestData: apiRequestData,
          userFullName: stripo.UserFullName,
          versionHistory: {
            changeHistoryLinkId: 'changeHistoryLink',
            onInitialized: function (lastChangeUndoText) {
              $('#changeHistoryContainer').show();
            }
          },
          viewOnly: stripo.viewOnly,
          externalMergeTags: ExternalMergeTags,
          externalImagesLibrary: ExternalImageLibrary,
          ignoreClickOutsideSelectors: '#externalImagesLibrary',
          getAuthToken: async function (callback) {
            var result = await stripo.tokenFunction();
            if (result.status == 200)    
              callback(result.data);
            else
              callback(null);
          },
          onTemplateLoaded: function () {
            if ((template.tenantId != undefined) && (template.tenantId != null))
              stripo.TenantId = template.tenantId;
            if ((template.templateId != undefined) && (template.templateId != null))
              stripo.TemplateId = template.templateId;

            stripo.externalImagesLibrary = ExternalImageLibrary;
            stripo.addPreviewImageClickEventHandlers();
          }
        });
      };
      document.body.appendChild(script);
    },

    stop: function() {
      window.StripoApi.stop();
    }
  }
}) (stripo || {})
