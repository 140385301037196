//******************************************************************************************
// Built and Tested with Stripo Version 20210730-1706_33bccf8  (Release 4.119 - August 2021)
// We are always using the latest release.
// https://stripo.email/releases/ - Monthly releases (rapid release, continuous bug fix)
//******************************************************************************************

var ExternalImageLibrary = (function () {
  return {        
    externalLibrary: string = '',
    imageSelectCallback: function () { },
    cancelCallback: function () { },
    onUploadFileCallback: function () { },
    thumbnails: string = '',
    evt: any = null,
    doAvatarProcessing: boolean = true,

    /**
     * Closes the Image Library pop-up.
     */
    close: function () {
      document.getElementById("#myFile").value = "";
      document.getElementById("#alertmsg").style.display = 'none';
      document.getElementById("#showmsg").style.display = 'block';
      ExternalImageLibrary.externalLibrary.style.visibility = 'hidden';
      $('#staticImageBackdrop').modal('hide');
    },

    /**
     * Updates the thumbnail HTML in the Image Library pop-up.
     *
     * @param {any} thumbnailHtml
     */
    updateThumbnails: function (thumbnailHtml) {
      let searchString = '[id ="imageThumbnails"]';
      let images = $(searchString);
      if ((images != undefined) && (images != null) && (images[0] != undefined) && (images[0] != null)) {
        images[0].innerHTML = thumbnailHtml;
      }
    },

    /**
     * Closes the Image Library pop-up and cancels the callback.
     */
    cancelAndClose: function () {
      ExternalImageLibrary.close();
      ExternalImageLibrary.cancelCallback();
    },

    initLibrary: function () {
      let div = document.createElement('div');
      div.innerHTML = '\
        <div id="externalImagesLibrary">\
          <!-- Modal --> \
          <div style="background-color: rgba(0,0,0,.71);" class="modal fade" id="staticImageBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticImageBackdropLabel" aria-hidden="true">\
            <div class="modal-dialog modal-dialog-centered" style="max-width:700px!important;">\
              <div class="modal-content" style="border:none !important;">\
                <div class="modal-header" style="background-color: #4b0ca5;padding: 25px;">\
                  <h5 class="modal-title text-white" id="staticImageBackdropLabel" style="font-size:24px;">Upload Image</h5>\
                  <button type="button" class="btn close fs-4 text-white" data-bs-dismiss="modal" aria-label="Close">\
                    <i class="fa fa-times" style="font-size:16px;"></i>\
                  </button>\
                </div>\
                <div class="modal-body" style="padding: 30px;">\
                  <div style="padding: 30px;text-align: center;background: #f5f5f5;margin-bottom: 20px;">\
                  <button pButton icon="pi pi-upload text-white" type="button" label="Upload Image" class="p-button p-component mb-3" onClick="document.getElementById(\'#myFile\').click()"><span class="p-button-icon p-button-icon-left pi pi-upload text-white" aria-hidden="true"></span><span class="p-button-label">Upload Image</span></button>\
                  <input type="file" id="#myFile" name="filename" accept="image/*" style="display:none;">\
                    <div><label id="#showmsg"><strong> File size limit: </strong> No larger than 1MB</label></div>\
                    <div><label style="color:red; font-weight:bold;" id="#alertmsg"> <span class="pi pi-times fw-bolder" style="font-size:13px !important;"></span> File size limit exceeded. Please upload an image no larger than 1MB.</label></div>\
                    <div><label style="color:red;font-weight:bold;" id="#alertInvalidMsg">Invalid file type, allowed file types: image/*.</label></div>\
                  </div>\
                  <div id="imageThumbnails" style="max-height: 486px; overflow-y:auto">' + ExternalImageLibrary.thumbnails +
                  '</div>\
                </div>\
              </div>\
            </div>\
          </div>\
        </div>';
      document.body.appendChild(div);

      document.getElementById("#myFile").onchange = function(event) { 
        if(!event.target.files[0].type.includes('image/') || event.target.files[0] == null || event.target.files[0].length == 0) {          
          document.getElementById("#myFile").value = "";
          document.getElementById("#showmsg").style.display = 'none';
          document.getElementById("#alertmsg").style.display = 'none';
          document.getElementById("#alertInvalidMsg").style.display = 'block';
          return;
        } 

        if (event.target.files[0].size > 1024000) {
          document.getElementById("#myFile").value = "";
          document.getElementById("#showmsg").style.display = 'none';
          document.getElementById("#alertmsg").style.display = 'block';
          document.getElementById("#alertInvalidMsg").style.display = 'none';
          return;
        }
        else {
          document.getElementById("#alertmsg").style.display = 'none';
          document.getElementById("#showmsg").style.display = 'block';
          document.getElementById("#alertInvalidMsg").style.display = 'none';
          ExternalImageLibrary.onUploadFileCallback(event.target.files[0]);
        }
      },

      ExternalImageLibrary.externalLibrary = document.getElementById('externalImagesLibrary');
      ExternalImageLibrary.externalLibrary.querySelector('.close').addEventListener('click', ExternalImageLibrary.cancelAndClose);
      ExternalImageLibrary.externalLibrary.addEventListener('click', function (e) {
        //e.stopPropagation();
        if (!e.target.matches('img')) {
          return;
        }
        let libraryImageClickedCallback = {
          originalName: e.target.getAttribute('title'),
          url: e.target.getAttribute('src'),
        };

        let imagePreview = document.getElementById('imgUploadSettingsControl-preview-image');
        if (imagePreview !== null) {
          imagePreviewStyle = imagePreview.style;
        }

        ExternalImageLibrary.imageSelectCallback(libraryImageClickedCallback);
        if (ExternalImageLibrary.doAvatarProcessing) {
          // search string for getting all images with the selected image's URL
          let searchString = '[src ="' + e.target.getAttribute('src') + '"]';
          let images = $(searchString);
          let isAvatar = false;

          if ((images != undefined) && (images != null)) {
            for (let x = 0; x < images.length; x++) {
              if (images[x].className == 'libraryImageAvatar') {
                isAvatar = true;
                break;
              }
            }

            if (isAvatar) {
              //for (let x = 0; x < images.length; x++) {
              //  images[x].style.borderRadius = "50%";
              //}
              if (imagePreview !== null) {
                imagePreviewStyle = imagePreview.style;
                imagePreviewStyle.borderRadius = "50%";
                imagePreviewStyle.maxWidth = "5.5rem";
                imagePreviewStyle.maxHeight = "5.5rem";
                imagePreviewStyle.minWidth = "5.5rem";
                imagePreviewStyle.minHeight = "5.5rem";
                imagePreviewStyle.objectFit = "cover";
              }
              stripo.makeAvatar(libraryImageClickedCallback.url);
            }
            else {
              if (imagePreview !== null) {
                imagePreviewStyle = imagePreview.style;
                imagePreviewStyle.borderRadius = "unset";
                imagePreviewStyle.maxWidth = "5.5rem";
                imagePreviewStyle.maxHeight = "5.5rem";
                imagePreviewStyle.minWidth = "5.5rem";
                imagePreviewStyle.minHeight = "5.5rem";
                imagePreviewStyle.objectFit = "contain";
              }
              stripo.clearAvatar(libraryImageClickedCallback.url);
            }
          }
        }

        ExternalImageLibrary.close();
      });
    },   

    setPreviewImageBorderRadius(e) {
      ExternalImageLibrary.evt = e;

      setTimeout(() => {
        let imagePreview = document.getElementById('imgUploadSettingsControl-preview-image');
        if ((imagePreview === undefined) && (imagePreview === null))
          return;

        if ((ExternalImageLibrary.evt.target.src == undefined) || (ExternalImageLibrary.evt.target.src == null)) {
          let image = ExternalImageLibrary.evt.target.children[0];
          if (imagePreview.src == image.src) {
            imagePreview.style.borderRadius = image.style.borderRadius;
            imagePreview.style.objectFit = image.style.objectFit;
            imagePreview.style.maxWidth = "5.5rem";
            imagePreview.style.maxHeight = "5.5rem";
            imagePreview.style.minWidth = "5.5rem";
            imagePreview.style.minHeight = "5.5rem";
          }
        }
        else {
          imagePreviewStyle = imagePreview.style;
          if (imagePreview.src == ExternalImageLibrary.evt.target.src) {
            imagePreviewStyle.borderRadius = ExternalImageLibrary.evt.target.style.borderRadius;
            imagePreview.style.objectFit = ExternalImageLibrary.evt.target.style.objectFit;
            imagePreview.style.maxWidth = "5.5rem";
            imagePreview.style.maxHeight = "5.5rem";
            imagePreview.style.minWidth = "5.5rem";
            imagePreview.style.minHeight = "5.5rem";
          }
        }
      }, 300); // If an avatar image is not changing in the preview image control, we will need to wait longer.
    },

    renderLibrary: function () {
      if (!ExternalImageLibrary.externalLibrary) {
        ExternalImageLibrary.initLibrary();
      }
      ExternalImageLibrary.externalLibrary.style.visibility = 'visible';
      $('#staticImageBackdrop').modal('show');
      document.getElementById("#alertmsg").style.display = 'none';
      document.getElementById("#alertInvalidMsg").style.display = 'none';
    },

    openLibrary: function (onImageSelectCallback, onCancelCallback, onUploadFileCallback, doAvatarProcessing = true) {
      ExternalImageLibrary.imageSelectCallback = onImageSelectCallback;
      ExternalImageLibrary.cancelCallback = onCancelCallback;
      if (onUploadFileCallback)
        ExternalImageLibrary.onUploadFileCallback = onUploadFileCallback;
      ExternalImageLibrary.doAvatarProcessing = doAvatarProcessing;
      ExternalImageLibrary.renderLibrary();
    }
  }
})(ExternalImageLibrary || {})