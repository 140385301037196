//******************************************************************************************
// Built and Tested with Stripo Version 20210730-1706_33bccf8  (Release 4.119 - August 2021)
// We are always using the latest release.
// https://stripo.email/releases/ - Monthly releases (rapid release, continuous bug fix)
//******************************************************************************************

var ExternalMergeTags = (function () {
  return {
    //tagGroups: string = '',
    popularTags: [], allTags: [],

    externalMergeTags: string = '', selectCallback: function () {
    },

    close: function () {
      ExternalMergeTags.externalMergeTags.style.visibility = 'hidden';
      $('#staticBackdrop').modal('hide');
    },

    cancelAndClose: function () {
      ExternalMergeTags.close();
    },

    // margin: T L R B
    initMergeTags: function (tags) {
      let div = document.createElement('div');
      div.innerHTML = `
        <div id="externalMergeTags" >
          <!-- Modal -->
          <div style="background-color: rgba(0,0,0,.71);" class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="max-width:700px!important;">
              <div class="modal-content" style="border:none !important;">
                <div class="modal-header" style="background-color: #4b0ca5;padding:25px;">
                  <h5 class="modal-title text-white" id="staticBackdropLabel" style="font-size:24px;">Merge Tags</h5>
                  <button type="button" style="border:none;" class="btn close fs-4 text-white" data-bs-dismiss="modal" aria-label="Close">
                    <i class="fa fa-times" style="font-size:22px; font-weight:500;"></i>
                  </button>
                </div>
                <div class="modal-body" style="padding: 0px;">
                  <div class="w-100" style="padding:25px;">
                      <input id="autoComplete" type="search" dir="ltr" class="border w-100" spellcheck=false autocorrect="off" autocomplete="off" autocapitalize="off" maxlength="2048" tabindex="1">
                  </div>
                  <div style="width: 100%; background: var(--light-gray); height: 1px;"></div>
                  <div style="width: 100%; background: var(--very-light-gray); height: 1px;"></div>
                  <div>
                    <div class="fw-bold" style="padding:25px 25px 10px 25px; color:#2e2e2e;">Popular Tags</div>
                    <div class="pt-0" id="popularTags" style="padding:20px;">
                      ${ExternalMergeTags.popularTags.map(item => "<span class='badge fs-6 rounded-pill text-black mx-1 my-2 tag-thumbnail' style='background-color: #e9f4f6' tag-value='" + item.tag + "'>" + item.shortLabel + "</span>").join(" ")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
`;
      // div.innerHTML = '\
      //   <div id="externalMergeTags" style="padding-top:200px; background-color: rgba(0,0,0,.1); position: fixed; top: 0; right: 0;  bottom: 0; left: 0; z-index: 1050; font-family: HelveticaNeue;">\
      //     <div style="margin: 0px;">\
      //       <div style="background-color: var(--primary-background-color); max-width: 900px; max-height: 750px; margin: 0 auto; border-radius: 3px !important; box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);">\
      //         <div style="padding: 8px;">\
      //           <table width="100%">\
      //             <tr>\
      //               <td><label style="font-size:1.25rem; font-weight: 700;">Merge Tags</label></td>\
      //               <td align="right"><button style="cursor:pointer; box-shadow:unset;" type="button" class="close p-column-filter-menu-button"><span class="pi pi-times"></span ></button></td>\
      //             </tr>\
      //           </table>\
      //         </div>\
      //       <div>\
      //       <div style="background: var(--light-gray); height: 1px; margin-left: 8px; margin-right: 8px;"></div>\
      //       <div style="background: var(--very-light-gray); height: 1px; margin-left: 8px; margin-right: 8px;"></div>\
      //     </div>\
      //   <table width="100%">\
      //     <tr>\
      //       <td><input id="autoComplete" type="search" dir="ltr" spellcheck=false autocorrect="off" autocomplete="off" autocapitalize="off" maxlength="2048" tabindex="1"></td>\
      //     </tr>\
      //     <tr>\
      //       <td valign="top">
      //       <div style="padding: 15px; overflow-y:scroll; max-height: 550px; margin-top: 8px;">'
      //         + ExternalMergeTags.popularTags.map(item => "<div class='thumbnail tag-thumbnamil' tag-value='" + item.NTag + "'>" + item.shortLabel + "</div>").join(" ") + '</div></td>\
      //     </tr>\
      //   </table>\
      // </div>';
      document.body.appendChild(div);
      const autoCompleteJS = new autoComplete({
        selector: "#autoComplete", placeHolder: "Search by keyword", data: {
          src: ExternalMergeTags.allTags, cache: true, keys: ["shortLabel"]
        }, threshold: 2, debounce: 100, // Milliseconds value
        resultsList: {
          element: (list, data) => {
            if (!data.results.length) {
              // Create "No Results" message element
              const message = document.createElement("div");
              // Add class to the created element
              message.setAttribute("class", "no_result");
              // Add message text content
              message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
              // Append message element to the results list
              list.prepend(message);
            }
          }, noResults: true, maxResults: 15
        }, resultItem: {
          highlight: false
        }, events: {
          input: {
            selection: (event) => {
              const selection = event.detail.selection.value.tag;
              autoCompleteJS.input.value = "";
              const mergeTagClickedCallback = selection;
              ExternalMergeTags.selectCallback(mergeTagClickedCallback);
              ExternalMergeTags.close();
            }
          }
        }
      });
      ExternalMergeTags.externalMergeTags = document.getElementById('externalMergeTags');
      ExternalMergeTags.externalMergeTags.querySelector('.close').addEventListener('click', ExternalMergeTags.cancelAndClose);
      ExternalMergeTags.externalMergeTags.addEventListener('click', function (e) {
        e.stopPropagation();
        if (!e.target.matches('.tag-thumbnail')) {
          return;
        }
        const mergeTagClickedCallback = e.target.getAttribute('tag-value');
        ExternalMergeTags.selectCallback(mergeTagClickedCallback);
        ExternalMergeTags.close();
      });
    },

    renderMergeTags: function () {
      if (!ExternalMergeTags.externalMergeTags) {
        ExternalMergeTags.initMergeTags(ExternalMergeTags.allTags);
      }
      externalMergeTags.style.visibility = 'visible';
      $('#staticBackdrop').modal('show');
    },

    open: function (mergeTagSelectCallback) {
      ExternalMergeTags.selectCallback = mergeTagSelectCallback;
      ExternalMergeTags.renderMergeTags();

    },

    changePopularTags: function(newPopularTags) {
      ExternalMergeTags.popularTags = newPopularTags;
      var ptagElement = document.getElementById('popularTags');
      if (ptagElement){
        var child = ptagElement.lastElementChild; 
        while (child) {
          ptagElement.removeChild(child);
          child = ptagElement.lastElementChild;
        }
        ptagElement.innerHTML = ExternalMergeTags.popularTags.map(item => "<span class='badge fs-6 rounded-pill text-black mx-1 my-2 tag-thumbnail' style='background-color: #e9f4f6' tag-value='" + item.tag + "'>" + item.shortLabel + "</span>").join(" ");
      }
    }
  }
})(ExternalMergeTags || {})
